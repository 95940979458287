/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 10px;
  margin-top: 200px;
  gap: 10px;
}

.buttonLogin {
  padding: 20px;
}

#root{
  height: 100%;
  overflow: hidden;
}

html, body {
  height: 100%;
  margin: 0px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.card{
  margin: auto;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;
  margin-top: 10px;
}

.tituloCardTest{
  border-radius: 10px !important;
  text-align: center;
}

.buscador{
  display: flex;
  align-items:center;
  flex-direction: column;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.botonAgrReg{
  display: flex;
  align-items:center;
  flex-direction: column;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.body{
  display: flex;
  /* align-items:flex-start; */
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;


}
.bodyRegForm{
  display: flex;
  align-items:center;
  flex-direction: column;
  justify-content:center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 25px;
}
.textfield{
  margin-top: 30px !important;
  display: flex !important;
  background-color:white;
  width: 300px !important;
}
.slider{
  margin-top: 30px !important;
  display: flex !important;
  width: 400px !important;
  flex-direction: column;
}
.checkbox{
    margin-top: 30px !important;
    display: flex !important;
    width: 400px !important;
  flex-direction: row;
  align-items:center;
  justify-content:space-around !important;
}
.card{
  margin: 10px;
  flex-grow:4;
}
.errorYup{
    font-size: 12px !important;
    color: #FF0D10!important;
    /* margin-left: -290px;
    margin-top: -36px; */
    /* position: relative;
    left: 14px;
    top:-15px; */


}
.botonera{
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 25px;
}

.pageRegistradores{
  margin-top: 70px;
}
.pageNuevoElemento{

}
.pageHistorial{
  margin-top: 25px;
}

.snackbar{
  margin-left: 60px;
}

.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}

.tablaInc{
  padding: 40px;

}

.tableHeader{
  background-color: #f1f1f1;
}

.formBajo{

    display: flex;
    align-items:center;
    flex-direction: row;
    justify-content:center;
    flex-wrap: wrap;
    gap: 30px;


}
 .paperInc{
   margin: 30px;
   padding: 30px;
 }
.cardInc{
  /* margin: auto; */
  /* background-color: rgb(245, 245, 245) !important; */
  border-radius: 10px !important;
  border: 1px solid rgb(141, 141, 141)

}
.textfield2{
  margin-top: 15px !important;
  display: flex !important;
  width: 650px !important;
}
.tablaBaja{
  margin-top: 25px;
}

.pageConfiguracion{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.pageCardForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}


.cardCardForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px !important;
  /* min-height: 400px;
  min-width: 600px; */
  /* width: 100%; */
  /* padding: 20px; */
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;

}

.bodyList{
/* display: flex;
flex-direction: column; */
/* align-items: center; */
/* margin: 50px;
width: 100%; */
display: flex;
flex-direction: column;
align-items: center;
margin-right:40px;
margin-left: 40px;
}
 .itemList{

 }
.columnList{
 /* margin-right: 20px; */
}

.pageDialog{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.cardTrans1{
  margin: auto;
  padding: 30px;
  background-color: rgb(245, 245, 245, 0.6) !important;
  border-radius: 5px !important;
}
.buscadorTabla{
  margin-top: 10px;
}


.MuiTablePagination-toolbar{
  align-items: baseline;
}
.dialogTitle{
  text-align: center;
  font-weight: bold !important;
}

.dialogContent{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*Estilos FarEdge*/

.pageConfigFar {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  flex-wrap: wrap;
  gap:20px;
  margin-top: 50px;
}

.botonConfigFar{
  display: flex;
  align-items:center;
  flex-direction: column;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;

}

.divCardIp{
  margin-top: 12px;
  display: flex;
  flex-direction: row;
}
.divCardIp2{
 margin-left: 10px;
}
.divCardIp3{
  margin-top: 3px;
  margin-left: 10px;
 }

.cardListFar{
  margin: auto;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;
  margin-top: 10px;
  min-width: 250px;
  min-height: 150px;
  border: 1px solid rgb(141, 141, 141);
}
.divCardIp4{
  margin-top: 3px;
  margin-left: 76px;
 }
 .divCardIp5{
  margin-left: 15px;
  margin-bottom: 10px;
  padding-top: 10px;
 }
 .farNombre{
   font-weight: bold;
 }
 .bodyFarList{
  display: flex;
  align-items:flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 60px;
  margin: 30px;

}
.farDerecha{
  margin-left: 30px;
  justify-content: right;
}
.bodyFarDetalle{
  margin-top: 50px;
  display: flex;
  align-items:flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;


}

.detalleFar{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

}

.textfieldDetalle{
  margin-top: 30px !important;
  margin: 30px !important;
  display: flex !important;
  background-color:white;
  width: 250px !important;
}
.textfieldEquipo{
  margin: 10px !important;
  display: flex !important;
  background-color:white;
  width: 160px !important;
}

.buscador1{
  display: flex;
  align-items:center;
  flex-direction: column;
  margin: 20px !important;
  margin-top: 30px;
  margin-bottom: 30px;
}
.cardTrans2{
  margin: 10px;
  background-color: rgb(245, 245, 245, 0.6) !important;
  border-radius: 5px !important;
}
.cardTrans3{
  /* flex-wrap: wrap; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* width: 100%; */
  padding-top: 10px;
  margin: 20px;
  background-color: rgb(245, 245, 245, 0.6) !important;
  border-radius: 5px !important;
  padding-bottom: 30px;
}
.bodyEquipoList{
  display: flex;
  justify-content:space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px;
}
.buscador2{
  display: flex;
  align-items:center;
  flex-direction: column;
  margin-right: 40px !important;
  /* margin: 20px !important;
  margin-top: 30px; */
  /* margin-bottom: 30px; */
}
.tableTit{
  display: flex;
  flex-direction: row;

  justify-content:space-between;
}
.dividerEquipo{
  margin: 25px;
}

.tituloEq{
  margin-left: 10px;
  font-weight: bold;
  font-size:larger;
}

.botonAlarma{
  display: grid;
    align-content: center;
}

.formExCRUD{
  margin: 20px;
  display: flex;
  justify-content: space-around;
}
/* SMILEY DOGG-------------*/

.sdPage {
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;

}

.sdNavbar{
  display: flex;
  /* height: 100px; */
  align-items: center;
  justify-content: center;
}
.sdContent{
  background-color: rgb(255, 255, 255);
  /* height: calc(100vh - 150px); */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  /* overflow-y: scroll; */


}
.sdLogo {
  height: 70px;
  /* width: 378.79px; */
  min-width: 300px;
  max-width: 378.79px;
  background-size: cover;
  background-image:
    url("./components/img/sdLogo.jpg");
    margin-bottom: 10px;
  /* background-position: right; */
}
.sdLogo2 {
  height: 64px;
  /* width: 378.79px; */
    margin-bottom: 10px;
  /* background-position: right; */
}
.sdSteps{
  background-color: #fff;
  max-width: 1300px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.labelStep {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-top: 6px;

  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
.labelPreStep {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  /* line-height: 22px; */

  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
}

.sdCard {
  margin: 10px;
  border-radius: 15px !important;
}
.cardStyle {
  width: 220px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image: linear-gradient(
      to left,
      rgba(230, 230, 230, 0.568),
      rgba(233, 230, 230, 0.466)
    ),
    url("./components/img/style1.png");
}
.cardGender1 {
  min-width: 200px;
  /* width: 250px; */
height: 200px;
/* height: 250px;   */
margin: auto;

  background-color: rgb(245, 245, 245) !important;
  border-radius: 15px !important;
  border: 1px solid rgb(141, 141, 141);
 background-size:contain;
  background-image:
    url("./components/img/gender/male.png");
  /* flex-grow:4; */
}

/* width: 220px;
min-height: 200px;
background-color: rgba(0, 0, 0, 0.897)!important;
border-radius: 10px !important;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid rgb(141, 141, 141);
color: white; */

.cardGender2 {
  min-width: 200px;
  /* width: 250px; */
  height: 200px;  /* margin: auto; */

  /* margin: auto; */
  background-color: rgb(245, 245, 245) !important;
  border-radius: 15px !important;
  border: 1px solid rgb(141, 141, 141);

 background-size: contain;
  background-image:
    url("./components/img/gender/female.png");
  /* flex-grow:4; */
}
.cardManBody {
  min-width: 200px;
  /* width: 250px; */
height: 200px;
/* height: 250px;   */
margin: auto;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;

 background-size:contain;
  background-image: linear-gradient(
      to left,
      rgba(230, 230, 230, 0.568),
      rgba(233, 230, 230, 0.466)
    ),
    url("./components/img/Male.png");
  /* flex-grow:4; */
}
.cardWomanBody {
  min-width: 200px;
  /* width: 250px; */
height: 200px;
/* height: 250px;   */
margin: auto;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;

 background-size:contain;
  background-image: linear-gradient(
      to left,
      rgba(230, 230, 230, 0.568),
      rgba(233, 230, 230, 0.466)
    ),
    url("./components/img/Male.png");
  /* flex-grow:4; */
}
.stepOne{
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

/*color icono step activo*/
/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color:rgb(195, 157, 94)!important;
} */
/*----------------*/
.MuiSvgIcon-root.Mui-active{
  color:rgb(195, 157, 94)!important;


}

/*color icono step completado*/
/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color:rgba(195, 156, 94, 0.514)!important;
} */
.MuiSvgIcon-root.Mui-completed{

  color:rgb(195, 157, 94, 0.432)!important;

}
/*color icono step no comenzado*/
/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color:rgba(195, 156, 94, 0.514)!important;

} */
.MuiStepIcon-root{

  color:rgb(195, 157, 94, 0.432)!important;

  font-size: 1.8rem !important;

}
/*tamaño número*/
/* .css-117w1su-MuiStepIcon-text {
  font-size: 0.8rem !important;
} */
.MuiStepIcon-text{
  font-size: 0.8rem !important;

}
/*tamaño icono*/
/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 1.8rem !important;
} */

.sdButton{
  margin-left: 25px!important;
  margin-right: 25px!important;
}
.sdButtonSide{
  height: 25px !important;
  margin-left: 15px!important;
  margin-right: 15px!important;
}
.buttonGroup{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/*Slider*/
.css-xmer36-MuiSlider-root {
  /* color: rgb(0, 0, 0) !important; */
  /* color: rgb(195, 157, 94) !important; */
}
.css-1eoe787-MuiSlider-markLabel {
  /* font-weight: bold !important; */
}
.css-yafthl-MuiSlider-markLabel {
  /* font-weight: bold !important; */
}

.priceStep{
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

/*Carrousel*/
/* .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: rgb(0, 0, 0) !important;

} */
.MuiButton-text{
  color: rgb(0, 0, 0) !important;

}
/* .css-26w9jf-MuiMobileStepper-dot {
  background-color: rgb(0, 0, 0) !important;
} */
.MuiMobileStepper-dotActive{
  background-color: rgb(0, 0, 0) !important;

}
/*Spinner*/
/* .css-10jyip1-MuiLinearProgress-root {
background-color: rgb(195, 157, 94) !important;
} */
.MuiLinearProgress-root{

  background-color:rgb(195, 157, 94)!important;


}
/* .css-p53mz9-MuiLinearProgress-bar1 {
  background-color: rgb(29, 29, 29) !important;

} */
.MuiLinearProgress-bar1Indeterminate{
  background-color: rgb(29, 29, 29) !important;

}
.MuiLinearProgress-bar2Indeterminate{
  background-color: rgb(29, 29, 29) !important;

}
/* .css-xybj4j-MuiLinearProgress-bar2 {
  background-color: rgb(29, 29, 29) !important;
} */
/*Botones importar fotos*/
/* .css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(195, 157, 94) !important;
} */
.botonImport{
  background-color: rgb(195, 157, 94) !important;

}
.sdForm{
  display: flex;
  flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: wrap;

}
.sdTextfield{
  width: 800px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.subzoneTag{

    /* margin: auto; */
    /* background-color: rgb(245, 245, 245) !important; */
    border-radius: 5px !important;
    border: 1px solid rgb(141, 141, 141);
    padding-left: 5px;
    padding-right: 5px;
    /* padding-top: 1px; */
    /* padding-bottom: 1px; */
    min-width: 90px !important;
    margin: 3px;
    display: flex;
    justify-content: center;


}
.subzoneTagSelect{

  /* margin: auto; */
  background-color: rgb(195, 157, 94) !important;
  min-width: 90px !important;
  border-radius: 5px !important;
  border: 1px solid rgb(141, 141, 141);
  padding-left: 5px;
  padding-right: 5px;
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
  margin: 3px;
  display: flex;
  justify-content: center;



}
/*slider*/
/* .css-1gva72z-MuiSlider-root {
  color:black !important;
}
.css-1tdm95m-MuiSlider-root {
  color:black !important;
  color: rgb(195, 157, 94) !important;

} */
/*---------------NUEVO-----------------*/
.MuiSlider-thumbColorPrimary{
  color:rgb(195, 157, 94)!important;


}
.MuiSlider-rail{
  color:rgb(195, 157, 94)!important;
  opacity: 1 !important;

}
.MuiSlider-track{
  color:rgb(192, 162, 114)!important;


}
.MuiSlider-markLabel{
  font-size: 10px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.MuiSlider-markLabelActive{
  font-size: 10px !important;

}
.MuiSlider-mark{
  background-color: #fff !important;

}
/*----------------NUEVO FIN--------------------*/
/* .css-xxsb21-MuiSlider-markLabel {
  font-size: 10px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.css-19l5h75-MuiSlider-markLabel {
  font-size: 18px !important;
}
.css-qx5f9e-MuiSlider-rail {
  opacity: 1 !important;
}
.css-1wdhkzz-MuiSlider-mark {
  background-color: #fff !important;
} */

.cardStyleRea {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StyleRea.JPG");
}
.cardStylePor {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StylePor.JPG");
}
.cardStylePet {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StylePet.JPG");
}
.cardStyleFin {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StyleFin.JPG");
}
.cardStyleLet {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StyleLet.JPG");
}
.cardStyleGeo {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StyleGeo.JPG");
}
.cardStyleTra {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StyleTra.JPG");
}
.cardStyleNeo {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StyleNeo.JPG");
}
.cardStyleJap {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 10px !important;


  background-size: cover;
  background-image:
    url("./components/img/styles/StyleJap.JPG");
}
.cardStyleNot {
  width: 200px;
  min-height: 200px;
  background-color: rgba(0, 0, 0, 0.897)!important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(141, 141, 141);
  color: white;


  /* background-size: cover;
  background-image:
    url("./components/img/styles/StyleJap.JPG"); */
}
.cardStyleColor1 {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 15px !important;
  border: 1px solid rgb(141, 141, 141);

  background-size: cover;
  background-image:
    url("./components/img/colors/full_color.JPG");
}
.cardStyleColor2 {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 15px !important;
  border: 1px solid rgb(141, 141, 141);

  background-size: cover;
  background-image:
    url("./components/img/colors/single_color.JPG");
}
.cardStyleColor3 {
  width: 200px;
  min-height: 200px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 15px !important;
  border: 1px solid rgb(141, 141, 141);

  background-size: cover;
  background-image:
    url("./components/img/colors/black_and_white.JPG");
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px !important;
  margin-top: 60px !important;
  margin-bottom: 30px;
}

.thankStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-top: 6px;

  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
/*corazon color*/
.MuiSvgIcon-fontSizeLarge{
  /* color: rgb(178, 94, 195) !important; */
}
.heartColor{
  color:rgb(195, 157, 94)!important;

}